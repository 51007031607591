import React, { createContext, useContext, useReducer } from 'react'

const NavContext = createContext()
const NavDispatchContext = createContext()

const useNavState = () => {
  const context = useContext(NavContext)
  if (context === undefined) {
    throw new Error('useNavState must be used within a NavProvider')
  }
  return context
}

const useNavDispatch = () => {
  const context = useContext(NavDispatchContext)
  if (context === undefined) {
    throw new Error('useNavDispatch must be used within a NavProvider')
  }
  return context
}

const navReducer = (state, action) => {
  switch (action.type) {
    case 'IS_EXPANDED': {
      return { ...state, ...{ isExpanded: action.payload } }
    }
    case 'IS_OPEN': {
      return { ...state, ...{ isOpen: action.payload } }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const NavProvider = ({
  defaultState = {
    isExpanded: false,
    isOpen: false,
  },
  children,
}) => {
  const [state, dispatch] = useReducer(navReducer, defaultState)

  return (
    <NavContext.Provider value={state}>
      <NavDispatchContext.Provider value={dispatch}>
        {children}
      </NavDispatchContext.Provider>
    </NavContext.Provider>
  )
}

export { NavProvider, useNavState, useNavDispatch }
