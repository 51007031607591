module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@4.25.0_mt2spmgtmwvj7z4kip45rvektu/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","background_color":"#000000","theme_color":"#0343fb","display":"minimal-ui","icon":"src/assets/images/klick-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8f656c327c4ee32570eed1f80318bc2c"},
    },{
      plugin: require('../../../packages/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://covid19.klick.com","stripQueryString":true,"stripHash":true},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-material-ui@3.0.1_keo6jfcxyrfpb3c5jqo2nnawzu/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-styled-components@5.25.0_ikhjteq3jfzxrc2hukbxjrhyre/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@4.25.7_qah34rtyyuwvowgupavlyhmdmy/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
