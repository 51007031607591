import React from 'react'
import {
  AccessibilityProvider,
  EnhancedProvider,
  IntroProvider,
  ModalProvider,
  ScrollProvider,
  ThemeProvider,
} from '@klickmarketing/react-components'
import CssBaseline from '@material-ui/core/CssBaseline'

import { NavProvider } from './src/context/context-nav'
import { siteMetadata } from './config'

export const WrapRootElement = ({ element }) => {
  return (
    <ModalProvider>
      <EnhancedProvider>
        <NavProvider>
          <ScrollProvider>
            <AccessibilityProvider>
              <ThemeProvider
                brandName={siteMetadata.brandName}
                themeType={siteMetadata.themeType}
              >
                <IntroProvider>{element}</IntroProvider>
              </ThemeProvider>
            </AccessibilityProvider>
          </ScrollProvider>
        </NavProvider>
      </EnhancedProvider>
    </ModalProvider>
  )
}

export const WrapPageElement = ({ element }) => (
  <>
    <CssBaseline />
    {element}
  </>
)
