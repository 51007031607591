export const onRouteUpdate = (
  { location },
  pluginOptions = { stripQueryString: false, stripHash: false },
) => {
  const domElem = document.querySelector(`link[rel='canonical']`)
  const existingValue = domElem.getAttribute(`href`)
  const { hostname, protocol } = new URL(existingValue)

  if (existingValue && protocol && hostname) {
    let value = `${protocol}//${hostname}${location.pathname}`

    const { stripQueryString, stripHash } = pluginOptions

    if (!stripQueryString) {
      value += location.search
    }

    if (!stripHash) {
      value += location.hash
    }

    domElem.setAttribute(`href`, `${value}`)
  }
}
