export const APP_ID = 'covid19'
export const SITE_URL = 'https://covid19.klick.com'
export const STACKNAME = 'prod-covid19-main'
export const GIT_SHORT = '{{ GIT_SHORT }}'

export const GATSBY_GOOGLE_TAG_MANAGER_ID = 'GTM-MCWR5GZ'

export const LATEST_COVID_PROTOCOLS_PDF = {
  url: 'https://cdn.klick.com/klick-covid19',
  prefix: 'Klick_COVID19_Safety_Protocols_V',
}

export const siteMetadata = {
  siteTitle: `Klick COVID-19 Portal`,
  description: `The COVID-19 outbreak is changing every day. So is what we're doing to respond. From activating digital health to PPE donations for the frontlines.`,
  siteUrl: SITE_URL,
  author: `@klickhealth`,
  brandName: 'klick',
  themeType: 'onWhite',
  stackname: STACKNAME,
  gitShort: GIT_SHORT,
}
